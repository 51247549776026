<template>
  <div class="form-group">
    <ValidationProvider :name="name" :rules="rules" v-slot="{classes,errors}">
        <div class="control" :class="classes">
            <div class="cntr">
              <label :for="name" class="label-cbx">
                <input :name="name" :id="name" type="checkbox" class="invisible" :value="value"  @input="updateValue($event.target.checked)">
                <div class="checkbox">
                  <svg width="20px" height="20px" viewBox="0 0 20 20">
                    <path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"></path>
                    <polyline points="4 11 8 15 16 6"></polyline>
                  </svg>
                </div>
                {{label}}
              </label>
               <span>{{ errors[0] }}</span>
            </div>
        </div>
    </ValidationProvider>
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required, is } from 'vee-validate/dist/rules';
extend('is', {
  ...is,
  message: (field, values) => 'Por favor seleccione el campo ' + `${field}`
});
export default {
  name: 'Chexbox',
  props: {
    name: String,
    label: String,
    rules: String,
    value: String
  },
  mounted () {
    // this.$el.value = this.value;
  },
  methods: {
    updateValue(value){
      console.log(value);
      value = String(value)
      this.$emit('input', value);
    }
  },  
}
</script>