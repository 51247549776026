<template>
  <div class="form-group">
    <ValidationProvider :name="name" :rules="rules" v-slot="{classes,errors}">
        <div class="control" :class="classes">
            <label v-if="label" :for="name">{{label}}</label><!--@input="$emit('input', $event.target.value)"
            <input :type="type" :id="id" :name="name" :placeholder="placeholder" @search:blur="blur" :value="value" @input="updateValue($event.target.value)">-->
            <template v-if="classic">
              <v-select :inputId="inputId"  :options="options" :disabled="disabled"  :value="value" @input="updateValue" :placeholder="placeholder" ></v-select>
            </template>
            <template v-else>
                <v-select label="name" :filterable="false" :disabled="disabled" :options="options" @search="onSearch">
                  <template slot="no-options">
                    type to search GitHub repositories..
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      <img :src='option.owner.avatar_url'/> 
                      {{ option.full_name }}
                      </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      <img :src='option.owner.avatar_url'/> 
                      {{ option.full_name }}
                    </div>
                  </template>
                </v-select>
            </template>
            <span >{{ errors[0] }}</span>
        </div>
    </ValidationProvider>
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
extend('required', {
  ...required,
  message: (field, values) => 'Por favor coloque un dato en el campo ' + `${field}`
});
export default {
  name: 'combo-box',
  props: {
    label: String,
    rules: String,
    value: String,
    placeholder: String,
    options: Array,
    inputId: String,
    name: String,
    classic: {
      classic: Boolean,
      default: true
    },
    disabled: String
  },
  mounted () {
    // this.$el.value = this.value;
  },
  computed: {
      
   },
  methods: {
    updateValue(value){
      this.$emit("input", value)
    },

    // busqueda
    onSearch(search, loading) {
      if(search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    // search: _.debounce((loading, search, vm) => {
    //   fetch(
    //     `https://api.github.com/search/repositories?q=${escape(search)}`
    //   ).then(res => {
    //     res.json().then(json => (vm.options = json.items));
    //     loading(false);
    //   });
    // }, 350)
  },  
}
</script>