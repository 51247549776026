<template>
  <div class="form-group">
    <ValidationProvider :name="name" :rules="rules" v-slot="{classes,errors}">
        <div class="control" :class="classes">
            <div class="switchform">
              <p class="label">{{label}}</p>
              <div class="check">
                <input :name="name" :id="name" type="checkbox" :value="value"  @input="updateValue($event.target.checked)">
                <label :for="name"></label>
              </div>
              <span>{{ errors[0] }}</span>
            </div>
        </div>
    </ValidationProvider>
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required, is } from 'vee-validate/dist/rules';
extend('is', {
  ...is,
  message: (field, values) => 'Por favor seleccione el campo ' + `${field}`
});
export default {
  name: 'SwitchForm',
  props: {
    name: String,
    label: String,
    rules: String,
    value: String
  },
  mounted () {
    // this.$el.value = this.value;
  },
  methods: {
    updateValue(value){
      console.log(value);
      value = String(value)
      this.$emit('input', value);
    }
  },  
}
</script>