<template>
    <div id="profile" class="dashboard">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="edit-profile">
                        <card type="profile default">
                             <template slot="header">
                                <h1>{{$t('profile.title')}}</h1>
                            </template>
                        </card>
                        <div class="perfil-image row no-gutters">
                                <div class="image" :style="'background-image: url('+pictureuser+')'"></div>
                                <div class="name-profile">{{ this.nameuser }}</div>
                        </div>
                        <div class="config-profile">
                            <div class="row">
                                <div class="col-12 col-lg-3">
                                    <card type="profile default">
                                        <ul class="no-padding">
                                            <li :class="{'active-profile' : active == 'informacion' }" @click="activePanel('informacion')">
                                                <div class="left-profile">
                                                    <p class="bold">{{$t('profile.item1')}}</p>
                                                    <p>{{$t('profile.item1desc')}}</p>
                                                </div>
                                            </li>
                                            <li :class="{'active-profile' : active == 'seguiridad' }"  @click="activePanel('seguiridad')">
                                                <div class="left-profile">
                                                    <p class="bold">{{$t('profile.item2')}}</p>
                                                    <p>{{$t('profile.item2desc')}}</p>
                                                </div>
                                            </li>
                                            <li :class="{'active-profile' : active == 'notificacion' }"  @click="activePanel('notificacion')">
                                                <div class="left-profile">
                                                    <p class="bold">{{$t('profile.item3')}}</p>
                                                    <p>{{$t('profile.item3desc')}}</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="left-profile">
                                                    <p class="bold">{{$t('profile.item4')}}</p>
                                                    <p>{{$t('profile.item4desc')}}</p>
                                                </div>
                                            </li>
                                            <li :class="{'active-profile' : active == 'facturacion' }"  @click="activePanel('facturacion')">
                                                <div class="left-profile">
                                                    <p class="bold">{{$t('profile.item5')}}</p>
                                                    <p>{{$t('profile.item5desc')}}</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </card>
                                </div>
                                <div class="col-12 col-lg-9">
                                    <transition name="slide-fade">
                                        <card type="profile default" v-if="active == 'informacion'">
                                            <template slot="header">
                                                <h3>{{$t('profile.title')}}</h3>
                                            </template>
                                            <ValidationObserver v-slot="{ handleSubmit }">
                                                <form @submit.prevent="handleSubmit(updateData)">
                                                    <text-input type="text" :label="$t('profile.tab1.label1')" name="name" v-model="name" :placeholder="$t('profile.tab1.placeholder1')"></text-input>
                                                    <text-input type="email" :label="$t('profile.tab1.label2')" name="Email" disabled="disabled"  v-model="email" :placeholder="$t('profile.tab1.placeholder2')" rules="required"></text-input>
                                                    <text-input type="text" :label="$t('profile.tab1.label3')" name="Teléfono" v-model="cell" :placeholder="$t('profile.tab1.placeholder3')" rules="required" ></text-input>
                                                    <combo-box :options="country" name="País" inputId="Seleccv"  v-model="pais"  :label="$t('profile.tab1.label4')" rules="required"  :placeholder="$t('profile.tab1.placeholder4')" ></combo-box>
                                                    <combo-box :options="state" name="Departamento" inputId="Seleccv"  v-model="departamento"  :label="$t('profile.tab1.label5')" rules="required"  :placeholder="$t('profile.tab1.placeholder5')" ></combo-box>
                                                    <combo-box :options="city" name="Ciudad" inputId="Seleccv"  v-model="ciudad"  :label="$t('profile.tab1.label6')" rules="required"  :placeholder="$t('profile.tab1.placeholder6')" ></combo-box>
                                                    <div class="form-group text-right">
                                                        <button type="submit" class="btn btn-bextsocial" >{{$t('profile.tab1.btn')}}</button>
                                                    </div>
                                                </form>
                                            </ValidationObserver>
                                        </card>
                                    </transition>
                                    <transition name="slide-fade">
                                        <card type="profile default" v-if="active == 'seguiridad'">
                                            <template slot="header">
                                                <h3>{{$t('profile.item2')}}</h3>
                                                <p>{{$t('profile.tab2.subtitle')}}</p>
                                            </template>
                                        <ValidationObserver v-slot="{ handleSubmit }">
                                                <form @submit.prevent="handleSubmit(onSubmit)">
                                                    <!-- <text-input type="text" label="Nombre:" name="name" v-model="name" placeholder="Ingresa tu Nombre"></text-input> -->
                                                    <text-input type="password" :label="$t('profile.tab2.label1')" name="Contraseña" v-model="contrasena" vid="contrasena" :placeholder="$t('profile.tab2.placeholder1')" rules="required"></text-input>
                                                    <text-input type="password" :label="$t('profile.tab2.label2')" name="Confirmar contraseña" v-model="password"  :placeholder="$t('profile.tab2.placeholder2')" rules="required|confirmed:contrasena" ></text-input>
                                                    <div class="form-group text-right">
                                                        <button type="submit" class="btn btn-bextsocial" >{{$t('profile.tab1.btn')}}</button>
                                                    </div>
                                                </form>
                                            </ValidationObserver>
                                        </card>
                                    </transition>
                                    <transition name="slide-fade">
                                        <card type="profile default" v-if="active == 'notificacion'">
                                            <template slot="header">
                                                <h3>{{$t('profile.tab3.title')}}</h3>
                                                <p>{{$t('profile.tab3.subtitle')}}</p>
                                            </template>
                                            <ValidationObserver v-slot="{ handleSubmit }">
                                                <form @submit.prevent="handleSubmit(prueba)">
                                                    <!-- <text-input type="text" label="Nombre:" name="name" v-model="name" placeholder="Ingresa tu Nombre"></text-input> -->
                                                    <chexbox v-model="sendemail" :label="$t('profile.tab3.check1')" name="Envio" rules="required|is:true" />
                                                    <chexbox v-model="sendvul" :label="$t('profile.tab3.check2')" name="vulnerabilidades" rules="required|is:true" />
                                                    <SwitchForm v-model="comentarios" :label="$t('profile.tab3.item1')" name="Comentarios" />
                                                    <SwitchForm v-model="actualizaciones" :label="$t('profile.tab3.item2')" name="Actualizaciones" />
                                                    <SwitchForm v-model="recordatorios" :label="$t('profile.tab3.item3')" name="Recordatorios" />
                                                    <SwitchForm v-model="ofertas" :label="$t('profile.tab3.item4')" name="Ofertas" />
                                                    
                                                    <div class="form-group text-right">
                                                        <button type="submit" class="btn btn-bextsocial" >{{$t('profile.tab1.btn')}}</button>
                                                    </div>
                                                </form>
                                            </ValidationObserver>
                                        </card>
                                    </transition>
                                    <transition name="slide-fade">
                                        <card type="profile default" v-if="active == 'facturacion'">
                                            <template slot="header">
                                                <h3>Resumen de Facturas</h3>
                                            </template>
                                            <v-client-table 
                                                :data="tableData"
                                                :columns="columns" 
                                                :options="options">
                                            </v-client-table>
                                        </card>
                                    </transition>
                                </div>
                            </div>
                        </div>
                        <!-- <card type="profile default">
                            <template slot="header">
                                <h1>Perfil de usuario</h1>
                            </template>
                            <div class="row">
                                <div class="col-12 col-md-3 text-center">
                                        <upload-image 
                                            @crop-success="cropSuccess"
                                            @crop-upload-success="cropUploadSuccess"
                                            @crop-upload-fail="cropUploadFail"
                                            v-model="show"
                                                langType="en" :imgDataUrl="pictureuser" :width="width" :height="height" ></upload-image>
                                    <div class="image-crop" :style="'background-image: url('+pictureuser+')'">
                                        <a class="nav-link" @click="toggleShow"> <font-awesome-icon icon="camera" size="1x" /> </a>
                                    </div>
                                    
                                </div>
                                <div class="col-12 col-md-9">
                                    <ValidationObserver v-slot="{ handleSubmit }">
                                        <form @submit.prevent="handleSubmit(onSubmit)">
                                            <text-input type="text" label="Nombre:" name="name" v-model="name" placeholder="Ingresa tu Nombre"></text-input>
                                            <text-input type="password" label="Nueva Contraseña:" name="Contraseña" v-model="contrasena" placeholder="Ingresa tu nueva contraseña" rules="required|confirmed:password"></text-input>
                                            <text-input type="password" label="Confirma contraseña:" name="Confirmar contraseña" v-model="password" vid="password" placeholder="Confirma tu contaseña" rules="required" ></text-input>
                                            <div class="form-group text-right">
                                                <button type="submit" class="btn btn-bextsocial" >Guardar</button>
                                            </div>
                                        </form>
                                    </ValidationObserver>
                                </div>
                            </div>
                        </card> -->
                    </div>
                </div>
            </div>
        </div>
        <notifications group="foo" position="bottom right" />
    </div>
</template>


<script>

import Card from "@/components/card/Card.component";
import {mapState, mapMutations, mapActions} from "vuex";
import TextInput from "@/components/inputForms/InputForms.component";
import moment from "moment";
import UploadImage from '@/components/uploadimage/UploadImage.component';
import ComboBox from "@/components/combobox/ComboBox.component";
import Chexbox from "@/components/chexbox/ChexboxForms.component";
import SwitchForm from "@/components/switch/SwitchForms.component";
import VueDataTable from '@/components/datatables/VueDataTable.component'

export default {
    name: "Profile",
    components: {
        Card,
        TextInput,
        ComboBox,
        Chexbox,
        SwitchForm,
        "upload-image": UploadImage,
        VueDataTable
    },
    data() {
        let  localuser= JSON.parse(localStorage.getItem("user"));
        const vm = this
        return {
            active: 'informacion',
            name: '',
            email: localuser.email,
            cell: '',
            country: [],
            pais: '',
            state: [],
            departamento: '',
            city: [], 
            ciudad: '',
            contrasena: "",
            password: "",
            sendemail: "false",
            sendvul: "false",
            myFiles: [],
            show: false,
            width: 300,
            height: 300,
            comentarios: "No",
            actualizaciones: "No",
            recordatorios: "No",
            ofertas: "No",
            // COLUMNS OF TABLE ON BD
            columns: ['Fecha_compra', 'Fecha_pago', 'Fecha_expiracion', 'Id_Pago', 'Status', 'Ammount'],
            tableData: [],
            options: {
                headings: {
                    Ammount: 'TOTAL',
                    Status: 'ESTADO',
                    Fecha_compra: 'FECHA DE COMPRA',
                    Fecha_pago: 'FECHA DE ACTIVACIÓN',
                    Fecha_expiracion: 'FECHA DE FINALIZACIÓN',
                    Id_Pago: 'NÚMERO DE PAGO'
                },
                sortable: ['Periodo', 'Fecha_compra','Fecha_pago','Fecha_expiracion' , 'Id_Pago','Status', 'Ammount'],
                filterable: ['Periodo', 'Fecha_compra','Fecha_pago','Fecha_expiracion' , 'Id_Pago','Status']
            }
        }
    },
    computed: {
        ...mapState("user",["users", "pictureuser", "nameuser"]),
        ...mapState(["social"]),
    },
    methods: {
        ...mapMutations(["Carga"]),
        ...mapMutations("user", ["changePicture", "changeUser"]),
        ...mapActions("user", ["loadUser"]),
        toggleShow() {
            this.show = !this.show;
        },
            /**
			 * crop success
			 *
			 * [param] imgDataUrl
			 * [param] field
			 */
			cropSuccess(imgDataUrl, field){
                debugger;
                const auth0user = JSON.parse(localStorage.getItem("user")) 
                const prueba = this.$refs.fileinput;
                console.log(prueba)
			},
			/**
			 * upload success
			 *
			 * [param] jsonData  server api return data, already json encode
			 * [param] field
			 */
			cropUploadSuccess(jsonData, field){
                debugger;
				console.log('-------- upload success --------');
                const picture = JSON.parse(jsonData)
                console.log(picture);
                console.log('field: ' + field);
                let urlpicture = picture.user_metadata.picture.split("/uploads")[1];
                urlpicture = "/uploads"+urlpicture
                this.changePicture(urlpicture);
			},
			/**
			 * upload fail
			 *
			 * [param] status    server api return error status, like 500
			 * [param] field
			 */
			cropUploadFail(status, field){
                debugger;
				console.log('-------- upload fail --------');
				console.log(status);
				console.log('field: ' + field);
            },
        async changePassword(authuser){
            const response = await this.axios.patch("/bextsocial/auth0",{
                    password: this.password,
                    sub: authuser.sub
                })
                this.contrasena = "";
                this.password = "";
                return response;
        },
        async getCountry(){
            try{
                const {data} = await this.axios.get('https://www.universal-tutorial.com/api/getaccesstoken',{
                    headers: {
                        "Accept": "application/json",
                        "api-token": "JuX1ETuAXGnS4sUsjToyXDk_-Ys0j9HIADVIwnrEb2YPgme4BikDv4vfDHu5VSh2-Fg",
                        "user-email": "mateo.argalle@gmail.com"
                    }
                })
                console.log(data.auth_token);
                const response = await this.axios.get('https://www.universal-tutorial.com/api/countries/',{
                    headers: {
                        "Authorization": "Bearer " + data.auth_token,
                        "Accept": "application/json"
                    }
                })
                console.log(response.data);
                let result = [];
                response.data.forEach(element => {
                    result.push(element.country_name)
                });
                this.country = result
            }catch(error){
                console.log(error);
            }
        },
        async getState(newVal){
            try{
                const {data} = await this.axios.get('https://www.universal-tutorial.com/api/getaccesstoken',{
                    headers: {
                        "Accept": "application/json",
                        "api-token": "JuX1ETuAXGnS4sUsjToyXDk_-Ys0j9HIADVIwnrEb2YPgme4BikDv4vfDHu5VSh2-Fg",
                        "user-email": "mateo.argalle@gmail.com"
                    }
                })
                const response = await this.axios.get(`https://www.universal-tutorial.com/api/states/${newVal}`,{
                    headers: {
                        "Authorization": "Bearer " + data.auth_token,
                        "Accept": "application/json"
                    }
                })
                console.log(response.data);
                let result = [];
                response.data.forEach(element => {
                    result.push(element.state_name)
                });
                this.state = result
            }catch(error){
                console.log(error);
            }
        },
        async getCity(newVal){
            try{
                const {data} = await this.axios.get('https://www.universal-tutorial.com/api/getaccesstoken',{
                    headers: {
                        "Accept": "application/json",
                        "api-token": "JuX1ETuAXGnS4sUsjToyXDk_-Ys0j9HIADVIwnrEb2YPgme4BikDv4vfDHu5VSh2-Fg",
                        "user-email": "mateo.argalle@gmail.com"
                    }
                })
                const response = await this.axios.get(`https://www.universal-tutorial.com/api/cities/${newVal}`,{
                    headers: {
                        "Authorization": "Bearer " + data.auth_token,
                        "Accept": "application/json"
                    }
                })
                console.log(response.data);
                let result = [];
                response.data.forEach(element => {
                    result.push(element.city_name)
                });
                this.city = result
            }catch(error){
                console.log(error);
            }
        },
        async onSubmit(){
            try {
                this.Carga();
                const authuser = JSON.parse(localStorage.getItem("user"))
                // if(this.name == ""){
                    const response = await this.changePassword(authuser);
                    this.Carga();

                    console.log(response);
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: '',
                        text: 'Datos actualizados',
                        duration: 5000,
                        speed: 1000
                    })
                // }
                // else{
                //     const response = await this.changePassword(authuser);
                //     const respuesta =  await this.axios.patch("/bextsocial/auth0/username",{
                //         name: this.name,
                //         sub: authuser.sub
                //     })
                //     console.log(respuesta);
                    
                //     if(respuesta.status){
                //         this.changeUser(respuesta.data.name)
                //         this.name = "";
                //         this.Carga();
                        
                //             this.$notify({
                //                 group: 'foo',
                //                 type: 'success',
                //                 title: '',
                //                 text: 'Datos actualizados',
                //                 duration: 5000,
                //                 speed: 1000
                //             })
                //     }
                //     else{
                //         this.$notify({
                //             group: 'foo',
                //             type: 'error',
                //             title: 'Error de conexión',
                //             text: "Vuelva a intentarlo más tarde",
                //             duration: 5000,
                //             speed: 1000
                //         })
                //     }
                    
                // }
            } catch (error) {
                this.Carga()
                console.log(error)
                this.$notify({
                    group: 'foo',
                    type: 'error',
                    title: 'Error de conexión',
                    text: error,
                    duration: 5000,
                    speed: 1000
                })
            }
        },
        async updateData(){
            try {
                this.Carga();
                const authuser = JSON.parse(localStorage.getItem("user"))
                const respuesta =  await this.axios.patch("/bextsocial/auth0/username",{
                    name: this.name,
                    sub: authuser.sub
                })
                
                this.changeUser(respuesta.data.name)
                const response = await this.axios.patch("/bextsocial/user",{
                    id: JSON.parse(localStorage.getItem("vuex")).user.users[0].Id_Usuario,
                    celular: this.cell,
                    pais: this.pais,
                    depar: this.departamento,
                    ciudad: this.ciudad
                })
                this.Carga();
                await this.loadUser()
                if(response.data == "Datos actualizados"){
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: '',
                        text: 'Datos actualizados',
                        duration: 5000,
                        speed: 1000
                    })
                }
            } catch (error) {
                this.Carga();
                console.log(error);
                this.$notify({
                    group: 'foo',
                    type: 'error',
                    title: 'Error de conexión',
                    text: "Vuelva a intentarlo más tarde",
                    duration: 5000,
                    speed: 1000
                })
            }
            
        },
        activePanel(elem){
            this.active = elem
        },
        prueba(){
            alert("prueba")
        }
    },
    async created() {
        // this.Carga();
        this.getCountry();
        this.name = this.nameuser;
        this.cell = this.users[0].Telefono;
        this.pais = this.users[0].Pais;
        this.departamento = this.users[0].Departamento;
        this.ciudad = this.users[0].Ciudad;
        // Load Payment information
        const data =  this.social.filter(item => item.view == true)
        const id_empresa =  data.shift().Id_Empresa;
        const payments_data = await this.axios.post(`/bextsocial/getpaymentsinfo`, {id_empresa: id_empresa});
        if(payments_data) {
          this.tableData = payments_data.data;
        }
    },
    watch: {
        pictureuser: function (newVal, oldVal) {
          console.log(newVal, oldVal)
        },
        pais(newVal){
            if(newVal){
                this.getState(newVal)
            }
            else{
                this.state = [];
                this.departamento = '';
            }
        },
        departamento(newVal){
            if(newVal && this.pais){
                this.getCity(newVal)
            }
            else{
                this.city = [];
                this.ciudad = '';
            }
        }
    },
}
</script>
<style lang="scss" scope>

.VuePagination {
  text-align: center;
}

.vue-title {
  text-align: center;
  margin-bottom: 10px;
}

.vue-pagination-ad {
  text-align: center;
}

.glyphicon.glyphicon-eye-open {
  width: 16px;
  display: block;
  margin: 0 auto;
}

th:nth-child(3) {
  text-align: center;
}

.VueTables__child-row-toggler {
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}

.VueTables__child-row-toggler--closed::before {
  content: "+";
}

.VueTables__child-row-toggler--open::before {
  content: "-";
}
    
</style>