<template>
  <div>
    DataTable
  </div>
</template>

<script>
export default {

}
</script>